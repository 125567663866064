/**
 * Validate all fields in the container.
 *
 * - Fields with required attribute are all required.
 * - email field name should be "email".
 * - email confirm field name should be "email_confirm".
 */

export const isFormValid = ($container, validateList = true) => {
	const ERROR_REQUIRED_CLASS = "invalid-required";
	const ERROR_INVALID_EMAIL_CLASS = "invalid-email";
	const ERROR_INVALID_CONFIRM_CLASS = "invalid-confirm";

	const isFieldValid = (field, $container) => {
		let isValid = true;

		field.classList.remove(ERROR_REQUIRED_CLASS);
		field.classList.remove(ERROR_INVALID_EMAIL_CLASS);
		field.classList.remove(ERROR_INVALID_CONFIRM_CLASS);

		// Required validation.
		if (field.hasAttribute("required")) {
			if (field.value.trim()) {
				field.classList.remove(ERROR_REQUIRED_CLASS);
			} else {
				field.classList.add(ERROR_REQUIRED_CLASS);
				isValid = false;
			}
		}

		// Email validation.
		if ("email" === field.type && isValid) {
			if (
				!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9_-]+\.[a-zA-Z0-9-.]{2,61}$/.test(
					field.value
				)
			) {
				field.classList.add(ERROR_INVALID_EMAIL_CLASS);
				isValid = false;
			} else {
				field.classList.remove(ERROR_INVALID_EMAIL_CLASS);
			}
		}

		// Email Confirm Validation.
		if (field.name.includes("email_confirm")) {
			// Check if the field is in a modal.
			const $email = $container.find('[name="email"]');
			if (
				!$email.hasClass(ERROR_REQUIRED_CLASS) &&
				!$email.hasClass(ERROR_INVALID_EMAIL_CLASS)
			) {
				if ($email.val() !== field.value) {
					field.classList.add(ERROR_INVALID_CONFIRM_CLASS);
					isValid = false;
				} else {
					field.classList.remove(ERROR_INVALID_CONFIRM_CLASS);
				}
			}
		}

		return isValid;
	};

	let isValid = true;
	$container.find("[name]").each((index, field) => {
		if (!isFieldValid(field, $container)) {
			isValid = false;
		}
	});

	// Validate opt-in list.
	if (validateList) {
		const selector =
			"[name='newsletter_name[]'][type=hidden], [name='newsletter_name[]'][type=checkbox]:checked";

		const list = $container
			.find(selector)
			.map((index, field) => field.value)
			.get();

		if (!list.length) {
			$container.find(".error-invalid-list").show();
			isValid = false;
		} else {
			$container.find(".error-invalid-list").hide();
		}
	}

	return isValid;
};

export const replaceWithSuccess = ($form, mode = "signup") => {
	let message = "";

	switch (mode) {
		case "update":
			message = "Thank you! Your email preferences were updated successfully.";
			break;
		case "unsubscribe":
			message = "You are now unsubscribed from this ESSENCE Newsletter.";
			break;
		default:
			message =
				"Thanks for signing up to ESSENCE Newsletters.<br>You will receive a welcome email shortly.";
	}

	$form.replaceWith(`<div class="subscribe-form-success">${message}</div>`);
};

export const showErrorMessage = ($response) =>
	$response.text("Oops! Something went wrong. Please try again later.").show();

export const doSubscribeAjax = (
	$form,
	successCallback,
	failCallback,
	alwaysCallback
) => {
	const $button = $form.find("[type=submit]");
	const $response = $form.find(".subscribe-form-response");
	const $loading = $form.find(".subscribe-form-loading");

	$button.attr("disabled", "");
	$response.hide();
	$loading.show();

	const data = $form.serialize() + "&action=newsletter_subscribe";

	$.ajax({
		url: pubstack.ajaxUrl,
		method: "POST",
		dataType: "JSON",
		data,
	})
		.done(function (response) {
			trackCordialNewsletter($form);
			// check if this subscription process has multiple steps (e.g. Article inline NL form)
			if ("subscribe-next-step" !== $form.data("mode")) {
				if ($form.data("redirect")) {
					location.href = $form.data("redirect");
				} else {
					replaceWithSuccess($form, $form.data("mode"));
				}
			}
			successCallback && successCallback();
		})
		.fail(function (xhr, textStatus, error) {
			// Will need to reproduce this error in order to get the proper json tree structure
			if (
				xhr.responseJSON &&
				xhr.responseJSON.code === "CONTACTS_DUPLICATE_KEY"
			) {
				console.log("CONTACTS_DUPLICATE_KEY");
				showErrorMessage("Please try again soon.");
			} else {
				showErrorMessage($response);
				failCallback && failCallback();
			}
		})
		.always(function () {
			$loading.hide();
			$button.removeAttr("disabled");
			alwaysCallback && alwaysCallback();
		});
};

const trackCordialNewsletter = ($form) => {
	if (typeof crdl === "undefined") {
		return;
	}

	let event,
		properties = {};

	const email = $form.find("[name=email]").val();

	if ("update" === $form.data("mode")) {
		event = "preference_update";
	} else if ("unsubscribe" === $form.data("mode")) {
		event = "unsubscribe";
	} else {
		event = "newsletter_signup";
		properties["source"] = $form.find("[name=source]").val();
	}

	crdl("contact", { email }, {}); // This seems to be required to associate the event using email for the new sessions (e.g. the modal popup ).
	crdl("event", event, properties);
};
